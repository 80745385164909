import {Component, ElementRef, Input} from '@angular/core';
import { PageContents, ComponentType } from 'src/app/shared/models/product.model';
import {OsCardComponent} from '../os-card/os-card.component';
import {SwaggerDocsComponent} from "../swagger-docs/swagger-docs.component";
import {AppMarkdownComponent} from "../markdown/markdown.component";

@Component({
  selector: 'content-item',
  standalone: true,
  imports: [OsCardComponent, SwaggerDocsComponent, AppMarkdownComponent],
  templateUrl: './content-item.component.html',
  styleUrl: './content-item.component.scss',
})
export class ContentItemComponent {
  @Input() pageContent: PageContents;

  componentType = ComponentType;

  constructor(public elementRef: ElementRef) {}
}
