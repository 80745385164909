import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { FormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import {
  APP_ENVIRONMENT,
  EnvironmentService,
  TokenInterceptor,
} from 'adk-portal-shared';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { provideMarkdown } from 'ngx-markdown';
import { provideAuth } from 'angular-auth-oidc-client';
import { DialogService } from 'primeng/dynamicdialog';
import { PortalResolver } from './app/components/layout/base/portal.resolver';
import { ContentResolver } from './app/components/content/content.resolver';

const appEnviornment = new EnvironmentService(environment);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    PortalResolver,
    ContentResolver,
    provideAuth(environment.oidcConfig),
    importProvidersFrom(
      BrowserModule,
      FormsModule,
      AppRoutingModule,
    ),
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: APP_ENVIRONMENT, useValue: appEnviornment },
    DialogService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideMarkdown(),
  ],
}).catch((err) => console.error(err));
