<div class="content-container">
  <div class="content-data">
    <div class="content-header">
      <h1>{{ title }}</h1>
    </div>
    <div class="content-item">
      @for (item of pageContents; track $index) {
      <content-item [pageContent]="item"></content-item>
      }
    </div>
  </div>
  @if (hasMarkDownContent) {
  <div class="content-vertical-tabs">
    <ud-tab-menu
      [items]="verticalTabMenuItems"
      [vertical]="true"
      [activeItem]="activeItem"
    ></ud-tab-menu>
  </div>
  }
</div>
