import { Component, Input } from '@angular/core';
import { ButtonComponent } from '@accesso/ng-uds';
import { CardComponent } from '../../card/card.component';
import { ComponentContent } from 'src/app/shared/models/product.model';

@Component({
  selector: 'os-card',
  standalone: true,
  imports: [ButtonComponent, CardComponent],
  templateUrl: './os-card.component.html',
  styleUrl: './os-card.component.scss',
})
export class OsCardComponent {
  @Input() componentItem: ComponentContent;

  openLink(link: string) {
    window.open(link, '_blank');
  }
}
