import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { ProductService } from "src/app/core/services/product.service";
import { NavigationProduct } from "src/app/shared/models/product.model";

@Injectable()
export class PortalResolver implements Resolve<NavigationProduct[]>{

    constructor(private productService: ProductService) {}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NavigationProduct[]> {
        return this.productService.getNavigationProducts();
    }
}