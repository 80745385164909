/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChildren,
} from '@angular/core';
import { ComponentType, PageContents } from 'src/app/shared/models/product.model';
import { ContentItemComponent } from './content-item/content-item.component';
import { ActivatedRoute } from '@angular/router';
import { TabMenuComponent } from '@accesso/ng-uds';
import { VerticalTabMenuItem } from '../../shared/models/verticalTab.model';
import { AnchorNavigationService } from '../../core/services/anchor-navigation.service';
import { MarkdownEventService } from 'adk-portal-shared';
import { BaseComponent } from '../layout/base/base.component';
import { Page } from 'src/app/shared/models/product.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-content',
  standalone: true,
  imports: [ContentItemComponent, TabMenuComponent],
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss',
})
export class ContentComponent implements OnInit, OnDestroy {
  pageContents: PageContents[] = [];
  verticalTabMenuItems: VerticalTabMenuItem[] = [];
  activeItem: VerticalTabMenuItem = {};
  prevSelectedNavItemId: string;
  hasMarkDownContent: boolean;
  markdownLoadEvent$: Subscription;
  title: string;
  verticalScrollEnabled = false;
  anchorScrollingInProgress = false;
  @ViewChildren(ContentItemComponent)
  contentItemComponents: ContentItemComponent[];

  constructor(
    private route: ActivatedRoute,
    private anchorNavigationService: AnchorNavigationService,
    private elementRef: ElementRef,
    private markdownEventService: MarkdownEventService,
    private baseComponent: BaseComponent,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      const pageData = data.pageData as Page;
      if(pageData) {
        this.title = pageData.product.name + ' - ' + pageData.name;
        this.pageContents = pageData.pageContents || [];
        this.hasMarkDownContent = this.hasMarkdownContent(this.pageContents);
        if (this.hasMarkDownContent) {
          this.initVerticalScrollComponents();
        }
      }
    });
  }
  
  initVerticalScrollComponents() {
    this.markdownLoadEvent$ = this.markdownEventService.getMarkDownLoadEvent().subscribe((value) => {
      if (value) {
        this.initAnchorNavigationItems();
        this.registerVerticalTabScrollEvent();  
      }
    });
  }

  hasMarkdownContent(pageContents: PageContents[]): boolean {
    return pageContents
        ? pageContents.some((item) => item.componentType === ComponentType.MARKDOWN)
        : false;
}

  registerVerticalTabScrollEvent() {
    if (
      this.baseComponent &&
      this.baseComponent.contentWrapper &&
      this.baseComponent.contentWrapper.nativeElement
    ) {
      this.baseComponent.contentWrapper.nativeElement.addEventListener(
        'scroll',
        this.handleScroll.bind(this)
      );
    }
  }

  scrollToView(activeItemId: string) {
    const element = document.getElementById(activeItemId);
    if (!element) {
      return;
    }
    this.anchorScrollingInProgress = true;
    element.scrollIntoView({
      behavior: 'auto',
    });

    this.anchorNavigationService.appendIdToCurrentRoute(activeItemId);
  }

  initAnchorNavigationItems() {
    const contentItemDiv = this.getContentItemDiv();
    if (!contentItemDiv) return;
    const h2Tags = this.getH2Tags(contentItemDiv);
    this.setVerticalTabMenuItems(h2Tags);
    this.setActiveItem();
    this.subscribeToFragmentChanges();
  }

  getContentItemDiv() {
    return this.elementRef.nativeElement.querySelector('.markdown-wrapper');
  }

  getH2Tags(contentItemDiv: any) {
    return contentItemDiv.querySelectorAll('h2');
  }

  setVerticalTabMenuItems(h2Tags: any) {
    this.verticalTabMenuItems = Array.from(h2Tags).map((h2: any) => {
      const title = h2.innerText.trim();
      const id = this.anchorNavigationService.createAnchorIDForScrolling(title);
      h2.setAttribute('id', id);
      return {
        label: title,
        id: id,
        command: () => {
          this.scrollToView(id);
          const verticalTab = this.verticalTabMenuItems.find(
            (item) => item.id === id
          );
          if (verticalTab) {
            this.activeItem = verticalTab;
          }
        },
      };
    });
  }

  setActiveItem() {
    this.activeItem =
      this.verticalTabMenuItems.length > 0 ? this.verticalTabMenuItems[0] : {};
  }

  subscribeToFragmentChanges() {
    if (this.verticalTabMenuItems.length) {
      this.route.fragment.subscribe((fragment) => {
        if (fragment) {
          this.scrollToView(fragment);
          const verticalTab = this.verticalTabMenuItems.find(
            (item) => item.id === fragment
          );
          if (verticalTab) {
            this.activeItem = verticalTab;
          }
        }
      });
    }
  }

  handleScroll() {
    if (this.anchorScrollingInProgress) {
      this.anchorScrollingInProgress = false;
      return;
    }

    const contentItemDiv = this.getContentItemDiv();
    if (!contentItemDiv) return;

    const scrollPosition =
      this.baseComponent.contentWrapper.nativeElement.scrollTop;
    const h2Tags = Array.from(this.getH2Tags(contentItemDiv));
    const initialScrollerPosition = 100;
    const contentItemWindows = h2Tags.map((h2: any, i, array: any) => {
      const currentOffsetTop = h2.getBoundingClientRect().top + scrollPosition;
      const nextOffsetTop =
        i < array.length - 1
          ? array[i + 1].getBoundingClientRect().top + scrollPosition
          : currentOffsetTop + h2.getBoundingClientRect().height;

      return {
        id: h2.id,
        offsetTop: currentOffsetTop - initialScrollerPosition,
        offsetBottom: nextOffsetTop - initialScrollerPosition,
      };
    });

    const activeWindow = contentItemWindows.find(
      (window) =>
        window.offsetTop <= scrollPosition &&
        window.offsetBottom >= scrollPosition
    );

    if (activeWindow) {
      const verticalTab = this.verticalTabMenuItems.find(
        (v) => v.id === activeWindow.id
      );
      if (verticalTab && this.activeItem.id !== verticalTab.id) {
        this.activeItem = verticalTab;
      }
    }
  }

  ngOnDestroy(): void {
    this.markdownLoadEvent$?.unsubscribe();
  }
}
