import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RouterOutlet, Router, ActivatedRoute } from '@angular/router';
import {
  FooterComponent,
  HomeComponent,
  NavbarComponent,
} from 'adk-portal-shared';
import { ContentAnimateDirective } from '../../../core/content-animate/content-animate.directive';
import { NgIf } from '@angular/common';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { NavigationProduct } from 'src/app/shared/models/product.model';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  standalone: true,
  imports: [
    SidebarComponent,
    NavbarComponent,
    NgIf,
    ContentAnimateDirective,
    RouterOutlet,
    FooterComponent,
    HomeComponent,
  ],
})
export class BaseComponent implements OnInit {
  navigationProducts: NavigationProduct[] = [];
  @ViewChild('contentWrapper') contentWrapper: ElementRef;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({navigationItems}) => {
      this.navigationProducts = navigationItems;
      if(this.router.url === '/portal') {
        this.navigateToFirstPageInProducts(this.navigationProducts);
      }
    });
  }

  navigateToFirstPageInProducts(products: NavigationProduct[]): void {
    for (const product of products) {
      if (product.pages && product.pages.length > 0) {
        const firstPage = product.pages[0];
        this.router.navigate(['portal/' + firstPage.fullRoute.slice(1)]);
        return; 
      }
      if (product.childProducts && product.childProducts.length > 0) {
        return this.navigateToFirstPageInProducts(product.childProducts);
      }
    }
  }
}
