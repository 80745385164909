import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of, switchMap } from "rxjs"; // Import 'of' for creating observables
import { ProductService } from "src/app/core/services/product.service";
import { BasePage, NavigationProduct, Page } from "src/app/shared/models/product.model";

@Injectable()
export class ContentResolver implements Resolve<Page | null> {

    constructor(private productService: ProductService,
        private router: Router
    ) {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Page | null> {
        if (!route.url.length) {
            return of(null);
        }

        const extractedPath = '/' + route.url.map(segment => segment.path).join('/');
        return this.productService.getNavigationProducts().pipe(
            switchMap((products) => this.findPageInProducts(products, extractedPath))
        );
    }

    pageNotFoundHandler(): Observable<null> {
        this.router.navigate(['/error'], {
          queryParams: {
            landingPage: 'portal',
          }
        });
        return of(null);
      }
  
    findPageInProducts(products: NavigationProduct[], path: string): Observable<Page | null> {
      const page = this.returnPageFromProducts(products, path);
      if (page) {
        return this.productService.getPageData(page?.id);
      } else {
        return this.pageNotFoundHandler();
      }
    }
  
    returnPageFromProducts(products: NavigationProduct[], path: string): BasePage | null {
      for (const product of products) {
        const page = product.pages?.find(p => p.fullRoute === path);
        if (page) {
          return page;
        }
  
        if (product.childProducts && product.childProducts.length > 0) {
          const foundPage = this.returnPageFromProducts(product.childProducts, path);
          if (foundPage) {
            return foundPage;
          }
        }
      }
      return null;
    }
    
}