import {ChangeDetectorRef, Component, Input, AfterViewInit} from '@angular/core';
import { ComponentContent } from 'src/app/shared/models/product.model';
import {SwaggerDocComponent} from "./swagger-doc/swagger-doc.component";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'swagger-docs',
  standalone: true,
  imports: [
    SwaggerDocComponent,
    DropdownModule,
    FormsModule,
  ],
  templateUrl: './swagger-docs.component.html',
  styleUrl: './swagger-docs.component.scss'
})
export class SwaggerDocsComponent implements AfterViewInit {
  @Input() items: ComponentContent[] | undefined;
  selectedItem: ComponentContent | undefined;
  selectedLink: string;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.selectedItem = this.items && this.items[0] ? this.items[0] : undefined;
    this.selectedLink = this.items && this.items[0]?.link ? this.items[0].link : '';
    this.cdr.detectChanges();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectionChange(value: any) {
    if (this.selectedLink !== value) {
      this.selectedLink = value;
      this.cdr.detectChanges();
    }
  }
}
