<div>
    <div class="dropdown-wrapper">
        <p-dropdown
                [options]="items"
                [(ngModel)]="selectedLink"
                (onChange)="onSelectionChange($any($event).value)"
                optionLabel="name"
                optionValue="link"
                placeholder="Select a Spec" />
    </div>

    @if (selectedLink) {
        <swagger-doc
            [link]="selectedLink"
            ></swagger-doc>
    }
</div>
