import { Environment } from 'adk-portal-shared';
import { LogLevel as OIDCLogLevel } from 'angular-auth-oidc-client';
import { LogLevel as AppLogLevel } from '../app/shared/models/log-level';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let require: any;

export const environment: Environment = {
  production: true,
  oidcConfig: {
    config: {
      authority:
        'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_GzTbSpXD3/',
      redirectUrl: window.location.origin + '/callback-oidc',
      postLogoutRedirectUri: window.location.origin,
      clientId: '4p2bk8sn3si9o2fhhu4980er8',
      scope: 'openid phone email profile',
      postLoginRoute: 'portal',
      responseType: 'code',
      silentRenew: false,
      useRefreshToken: true,
      allowUnsafeReuseRefreshToken: true,
      logLevel: OIDCLogLevel.Debug,
    },
  },
  logLevel: AppLogLevel.INFO,
  appVersion: require('../../package.json').version,
  baseUrl: 'https://dev.developer.accesso.com',
  adkPortalApiBaseUrl: 'https://api.dev.developer.accesso.com',
  defaultLandingRoute: 'portal',
  s3BucketBaseUrl: 'https://docs.dev.developer.accesso.com',
  googleAnalyticsId: 'G-LJ86VVX4SP',
};
