<div class="dp-card" [class.os-card]="isOsCard" [class.full-card]="isFullCard">
    @if (item && item.name && item.subHeader && item.iconLink) {
        <div class="dp-card-detail">
            <div class="card">
                <h3>{{ item.name }}</h3>
                <div class="subtitle-3">{{ item.subHeader }}</div>
            </div>

            <img [src]="item.iconLink" height="48" alt="os-card" />
        </div>
    }
    <div class="dp-card-body">
        <ng-content></ng-content>
    </div>
</div>
