<div class="content-item-container">
  @switch (pageContent.componentType) {
    @case (componentType.MARKDOWN) {
      @for (componentContent of pageContent.componentContents; track $index) {
        <app-markdown [markdownUrl]="componentContent.link"></app-markdown>
      }
    }

    @case (componentType.DOWNLOAD) {
      <div class="os-card-items">
        @for (componentContent of pageContent.componentContents; track $index) {
          <os-card [componentItem]="componentContent"></os-card>
        }
      </div>
    }

    @case (componentType.SWAGGER) {
      <div class="swagger-docs">
        <swagger-docs [items]="pageContent.componentContents"></swagger-docs>
      </div>
    }
  }
</div>
