import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AnchorNavigationService {
  constructor(private router: Router) {}

  createAnchorIDForScrolling(anchor: string) {
    return anchor.replace(/ /g, '-').toLowerCase();
  }

  appendIdToCurrentRoute(id: string) {
    const currentUrlTree = this.router.parseUrl(this.router.url);
    currentUrlTree.fragment = id;
    const newUrl = this.router.serializeUrl(currentUrlTree);
    window.history.replaceState({}, '', newUrl);
  }
}
