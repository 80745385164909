export type TLogLevel = 'DEBUG' | 'INFO' | 'WARN' | 'ERROR';
export const LogLevel: Record<TLogLevel, ILogLevel> = {
  DEBUG: { id: 'DEBUG', name: 'debug', precedence: 0 },
  INFO: { id: 'INFO', name: 'info', precedence: 10 },
  WARN: { id: 'WARN', name: 'warn', precedence: 20 },
  ERROR: { id: 'ERROR', name: 'error', precedence: 30 },
};

export interface ILogLevel {
  id: TLogLevel;
  name: string;
  precedence: number;
}
