<div class="main-wrapper">
    <div class="page-wrapper">
      <div class="side-bar-wrapper">
        <app-sidebar [navigationProducts]="navigationProducts"></app-sidebar>
      </div>
      <div class="content-wrapper" #contentWrapper contentAnimate>
        <router-outlet></router-outlet>
      </div>
    </div>
</div>
