import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ErrorNavigationService {
  constructor(private router: Router) {}

  handleError(error: HttpErrorResponse) {
    switch (error.status) {
      case 404:
        this.router.navigate(['/error/404']);
        break;
      case 403:
        this.router.navigate(['/error/403']);
        break;
      case 401:
        this.router.navigate(['/error/401']);
        break;
      case 500:
        this.router.navigate(['/error/500']);
        break;
      default:
        this.router.navigate(['/error']);
        break;
    }
  }
}
