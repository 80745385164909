import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
  FooterComponent,
  HomeComponent,
  NavbarComponent,
} from 'adk-portal-shared';
import { NgIf } from '@angular/common';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  standalone: true,
  imports: [
    SidebarComponent,
    NavbarComponent,
    NgIf,
    RouterOutlet,
    FooterComponent,
    HomeComponent,
  ],
})
export class LandingComponent {}
