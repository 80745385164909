export interface BasePage {
    id: number;
    name: string;
    route: string;
    fullRoute: string;
    precedence: number;
}

export enum ComponentType {
    MARKDOWN = "MARKDOWN",
    SWAGGER = "SWAGGER",
    DOWNLOAD = "DOWNLOAD"
}

export interface ComponentContent {
    id: number;
    name: string;
    link: string;
    editStatus: string;
    isDeprecated: boolean;
    precedence: number;
    subHeader?: string;
    iconLink?: string;
}

export interface PageContents {
    id: number;
    componentType: ComponentType;
    editStatus: string;
    componentContents: ComponentContent[];
}

interface Product {
    id: number;
    name: string;
    route: string;
    editStatus: string;
    precedence: number;
}

export interface Page extends BasePage {
    editStatus: string;
    product: Product;
    pageContents: PageContents[];
}

export interface NavigationProduct {
    id: number;
    name: string;
    precedence: number;
    pages?: BasePage[];
    childProducts?: NavigationProduct[];
}