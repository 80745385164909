import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService, FooterComponent, GoogleAnalyticsService, NavbarComponent, SessionService } from 'adk-portal-shared';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NavbarComponent, FooterComponent, SidebarComponent],
})
export class AppComponent implements OnInit, OnDestroy{
  
  private loggedInSubscription$: Subscription | undefined;
  constructor(private authService: AuthService,
    private sessionService: SessionService,
    private googleAnalyticsService: GoogleAnalyticsService,) {
  }

  ngOnInit(): void {
    this.loggedInSubscription$ = this.authService.getLoggedInEvent().subscribe((loggedIn) => {
      if (loggedIn) {
        this.sessionService.setupSessionRenewal();
        this.googleAnalyticsService.initialize();
      }
    });
  }

  ngOnDestroy(): void {
    this.loggedInSubscription$?.unsubscribe();
  }

}
