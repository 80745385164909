import { Component, Input } from '@angular/core';
import { MarkdownComponent } from 'ngx-markdown';
import { MarkdownEventService } from 'adk-portal-shared';

@Component({
  selector: 'app-markdown',
  standalone: true,
  imports: [MarkdownComponent],
  templateUrl: './markdown.component.html',
  styleUrl: './markdown.component.scss',
})
export class AppMarkdownComponent {
  @Input() markdownUrl: string | undefined;

  constructor(private markdownEventService: MarkdownEventService) {}

  onload() {
    this.markdownEventService.emitMarkDownLoadEvent(true);
  }
}
