import { Component, Input, OnInit } from '@angular/core';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MenuItem } from 'primeng/api';
import { NavigationProduct } from 'src/app/shared/models/product.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [RouterLink, NgFor, NgIf, NgClass, PanelMenuModule],
})
export class SidebarComponent implements OnInit {
  constructor(private router: Router) {}

  items: MenuItem[];
  newItems: MenuItem[];
  @Input() navigationProducts: NavigationProduct[] | undefined;

  ngOnInit(): void {
    this.populateMenuItems();
  }

  populateMenuItems() {
    if (this.navigationProducts) {
      this.items = this.navigationProducts.map((product) => this.mapProductToMenuItem(product));
    } 
    if(this.router.url === '/portal') {
      this.expandFirstMenuItem();
    }
  }

  expandFirstMenuItem() {
    this.items = this.items.map((item, index) => {
      if(index === 0) {
        item.expanded = true;
      }
      return item;
    });
  }

  private mapProductToMenuItem(product: NavigationProduct): MenuItem {
    const menuItem: MenuItem = {
      label: product.name || '', 
      items: [],
    };
  
    if (product.pages) {
      const hasMatchingRoute = product.pages.some((navItem) => this.router.url.includes(navItem.fullRoute));
      if (hasMatchingRoute) {
        menuItem.expanded = true;
      }
      menuItem.items = product.pages.map((navItem) => ({
        label: navItem.name || '', 
        routerLink: navItem.fullRoute ? navItem.fullRoute.slice(1) : '',
      }));
    }
   
    if (product.childProducts) {
      const childItems = product.childProducts.map((childProduct) => this.mapProductToMenuItem(childProduct));
      menuItem.items = menuItem.items || [];
      menuItem.items.push(...childItems);
    }
  
    return menuItem;
  }
 }


