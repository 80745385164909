import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EnvironmentService, APP_ENVIRONMENT } from 'adk-portal-shared';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators'; // Import tap operator
import { NavigationProduct, Page } from 'src/app/shared/models/product.model';
import { ErrorNavigationService } from './error-navigation.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  
  private baseUrl: string;
  private navigationProducts: NavigationProduct[] | null = null; 

  constructor(private http: HttpClient,
              private errorNavigationService: ErrorNavigationService,  
              @Inject(APP_ENVIRONMENT) private environment: EnvironmentService) {
    this.baseUrl = `${environment.getEnvironmentProperties().adkPortalApiBaseUrl}/v1`;
  }

  getNavigationProducts(): Observable<NavigationProduct[]> {
    if (this.navigationProducts) {
      return of(this.navigationProducts); 
    }
    
    return this.http.get<NavigationProduct[]>(`${this.baseUrl}/products/nav`).pipe(
      tap(products => {
        this.navigationProducts = products; 
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorNavigationService.handleError(error);
        throw new Error('Error fetching navigation product data');
      })
    );
  }

  getPageData(pageId: number): Observable<Page> {
    return this.http.get<Page>(`${this.baseUrl}/pages/${pageId}`).pipe(
        catchError((error: HttpErrorResponse) => {
            this.errorNavigationService.handleError(error);
            throw new Error('Error fetching page data');
        })
    );
  }
}
