import {Component, Input, AfterViewInit, OnChanges} from '@angular/core';
import SwaggerUI from 'swagger-ui';
import {CardComponent} from "../../../card/card.component";

@Component({
  selector: 'swagger-doc',
  standalone: true,
  imports: [
    CardComponent
  ],
  templateUrl: './swagger-doc.component.html',
  styleUrl: './swagger-doc.component.scss'
})
export class SwaggerDocComponent implements AfterViewInit, OnChanges {

  @Input() link: string | undefined;

  ngAfterViewInit() {
    this.initSwaggerUI();
  }

  ngOnChanges() {
    this.initSwaggerUI();
  }

  initSwaggerUI() {
    const HideInfoUrlPartsPlugin = () => {
      return {
        wrapComponents: {
          InfoUrl: () => () => null
        }
      }
    }
    if (typeof this.link === 'undefined') {
      throw new Error("Must define componentItem to display Swagger Doc.")
    }
    SwaggerUI({
      dom_id: "#swaggerDoc",
      url: this.link,
      plugins: [
        HideInfoUrlPartsPlugin
      ]
    });
  }
}
