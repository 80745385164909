import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from './components/layout/base/base.component';
import {
  ErrorPageComponent,
  PostLoginGuard,
  TermsAndConditionGuard,
  TermsAndConditionComponent,
  OidcCallbackComponent,
} from 'adk-portal-shared';
import { ContentComponent } from './components/content/content.component';
import { LandingComponent } from './components/layout/landing/landing.component';
import { PortalResolver } from './components/layout/base/portal.resolver';
import { ContentResolver } from './components/content/content.resolver';

const routes: Routes = [
  {
    path: 'callback-oidc',
    component: OidcCallbackComponent,
  },
  {
    path: 'terms-and-condition',
    component: TermsAndConditionComponent,
  },
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'portal',
    component: BaseComponent,
    canActivate: [PostLoginGuard, TermsAndConditionGuard],
    resolve: {
      navigationItems: PortalResolver,
    },
    children: [
      { 
        path: '**',
        component: ContentComponent,
        resolve: {
          pageData: ContentResolver,
        }
      }
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: 'Page Not Found',
      desc: "The page you were looking for doesn't exist.",
    },
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent,
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
